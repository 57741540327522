import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import isSSR from '../utils/isSSR';
import useCartProductsData from '../hooks/useCartProductsData';
import PropTypes from '../utils/PropTypes';

const CART_ITEMS_STORAGE_KEY = 'do0aru8235dlsa04';

const CartStateContext = createContext();
export const CartDispatchContext = createContext();

function cartReducer(state, action) {
  switch (action.type) {
    case 'setCartItems': {
      localStorage.setItem(CART_ITEMS_STORAGE_KEY, JSON.stringify(action.payload.cartItems));

      return { ...state, cartItems: action.payload.cartItems };
    }
    case 'updateCartItem': {
      const cartItemQty = state.cartItems[action.payload.sku] || 0;

      const updatedCartItems = {
        ...state.cartItems,
        [action.payload.sku]: cartItemQty + action.payload.quantity,
      };

      return { ...state, cartItems: updatedCartItems };
    }
    case 'clearCartItems': {
      return { ...state, cartItems: {} }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const useCartState = () => {
  const state = useContext(CartStateContext);

  if (state === undefined) {
    throw new Error('useCartState must be used within a CartProvider');
  }

  return state;
};

const useCartActions = () => {
  const dispatch = useContext(CartDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useCartActions must be used within a CartProvider');
  }

  return {
    updateCartItem: (sku, quantity) => dispatch({ type: 'updateCartItem', payload: { sku, quantity } }),
    clearCartItems: () => dispatch({ type: 'clearCartItems' }),
  };
};

const getValidCartItems = (products) => {
  const cartItems = !isSSR ? (
    JSON.parse(localStorage.getItem(CART_ITEMS_STORAGE_KEY)) || []
  ) : [];

  const cartItemKeys = Object.keys(cartItems);

  return cartItemKeys.reduce((acc, key) => {
    if (products[key]) {
      return {
        ...acc,
        [key]: cartItems[key],
      }
    }

    return acc;
  }, {})
};

const CartProvider = ({ children }) => {
  const products = useCartProductsData();

  const [state, dispatch] = useReducer(cartReducer, {
    cartItems: getValidCartItems(products),
  });

  // Check if cartItems are updated and save to storage
  useEffect(() => {
    if (!isSSR) {
      localStorage.setItem(CART_ITEMS_STORAGE_KEY, JSON.stringify(state.cartItems));
    }

    const storageUpdateListener = (event) => {
      dispatch({ type: 'setCartItems', payload: { cartItems: getValidCartItems(products) } });
    };

    window.addEventListener('storage', storageUpdateListener);

    return () => {
      window.removeEventListener('storage', storageUpdateListener);
    }
  }, [state.cartItems]);

  return (
    <CartDispatchContext.Provider value={dispatch}>
      <CartStateContext.Provider value={state}>
        {children}
      </CartStateContext.Provider>
    </CartDispatchContext.Provider>
  );
};

CartProvider.propTypes = {
  children: PropTypes.node,
};
CartProvider.defaultProps = {
  children: null,
};

export {
  CartProvider,
  useCartActions,
  useCartState,
};
